import {useEffect, useRef, useState} from "react";
import { NavLink } from "react-router-dom";

import adminApi from "../../api/admin";
import { aggDateFromTimestamp, aggPhoto } from "../../helpers";
import Logo from "../../assets/user/tg-icon.png";
import TfLogo from "../../assets/user/tf-icon.png";
import TravelLogo from "../../assets/user/travel-icon.png";
import { aggProfileUrl } from "../../helpers/profile";
import VerificationHistory from "../Members/Verification/VerificationItem/VerificationHistory";
import { timeAgo } from "../../helpers/dates";
import { reportReasons } from "../../config/reportReasons";

const TopInfo = (props) => {
    const commentRef = useRef();
    const [showComments, setShowComments] = useState(false);
    const [showReportedGirls, setShowReportedGirls] = useState(false);
    const [ipDuplicates, setIpDuplicates] = useState(null);
    const [showIpDuplicates, setShowIpDuplicates] = useState(null);
    const [showVerificationHistory, setShowVerificationHistory] = useState(null);
    const [verificationHistoryList, setVerificationHistoryList] = useState(null);
    const [duplicates, setDuplicates] = useState([]);
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [reports, setReports] = useState(null);

    const [userState, setUser] = useState(props.user);
    let reportedUsersId = [];
    if (reports) {
        reportedUsersId = reports.map((report) => {
            return report.userId;
        });
    }

    const getCookieDuplicates = () => {
        adminApi.get(`users/get_cookie_dublicates/${userState.member.ID}`).then((r) => {
            setDuplicates(r.data.duplicates);
            setReports(r.data.reportList);
        });
    }

    useEffect(() => {
        if (props.user.comment) setShowComments(true);
        getCookieDuplicates();
    }, []);

    const saveComment = () => {
        setShowComments(false);
        let postData = {userId: userState.member.ID, comment: commentRef.current.value}
        adminApi.post('users/update_comment/', postData).then((r) => {
            setUser({
                ...userState,
                comment: postData.comment,
            });
        });
    }

    const getIpDuplicates = () => {
        if (showIpDuplicates === null) {
            adminApi.get('users/get_ip_duplicates/' + userState.member.ID).then((r) => {
                setIpDuplicates(r.data);
                setShowIpDuplicates(true)
            })
            return;
        }
        setShowIpDuplicates(!showIpDuplicates);
    }

    const giveDiscount = () => {
        if(window.confirm('Are you sure want to give a discount? (19eu; 29eu rebills)?')){
            adminApi.post('users/update/' + userState.member.ID, {tgPricepoint: "discount"}).then((r) => {
                setUser({
                    ...userState,
                    member: {
                        ...userState.member,
                        tgPricepoint: 'discount',
                    }
                });
            })
        }
    }

    const changeEscortStatus = () => {
        const escortValue = (parseInt(userState.member.isEscort) === 1) ? 0 : 1;
        adminApi.post('users/update/' + userState.member.ID, {isEscort: escortValue}).then((r) => {
            setUser({
                ...userState,
                member: {
                    ...userState.member,
                    isEscort: escortValue,
                }
            });
        })
    }

    const getVerificationHistory = () => {
        if (verificationHistoryList === null) {
            adminApi.get('verification/get_verification_history/' + userState.member.ID).then((r) => {
                setVerificationHistoryList(r.data);
                setShowVerificationHistory(!showVerificationHistory);
            });
        } else {
            setShowVerificationHistory(!showVerificationHistory);
        }

    }

    const addUserToBlackList = () => {
        if(window.confirm('Are you sure you want to add this user to blacklist?')){
            adminApi.post('users/add_user_to_cannot_pay/' + userState.member.ID).then((r) => {
                setUser({
                    ...userState,
                    member: {
                        ...userState.member,
                        canPay: false,
                    }
                });
            })
        }
    }

    const removeUserFromBlackList = () => {
        if(window.confirm('Are you sure you want to delete this user from blacklist?')){
            adminApi.post('users/remove_user_from_cannot_pay/' + userState.member.ID).then((r) => {
                setUser({
                    ...userState,
                    member: {
                        ...userState.member,
                        canPay: true,
                    }
                });
            })
        }
    }

    return (
        <>
            {userState.member.isConfirmedEver === '0' &&
                <div className="newProfileUpdate"><b className="updateIcon">N</b> <b>New profile update</b></div>
            }
            {userState.member.sex === 'female' &&
                <div>Received Warning: <strong>{parseInt(userState.member.isEscort) === 1 ? 'Yes ' : 'No '}</strong>
                <div className="table-links inline" onClick={changeEscortStatus}>Change</div>
            </div>
            }

            <div>Comments: <strong className="red">{userState.comment ? '1' : '0'} </strong>
                <div className="table-links inline" onClick={() => {setShowComments(!showComments)}} name="edit_comments">{showComments ? 'Hide' : 'See'} comment</div>
            </div>
            {showComments &&
                <div className="commentsBlock">
                    <textarea className="commentsTextarea" ref={commentRef} defaultValue={userState.comment}></textarea>
                    <button className="styledButton button_update" onClick={saveComment}>Save comment</button>
                </div>
            }

            {userState.member.sex === 'male' && userState.reportedGirls &&
                <>
                <div>Reported girls: <strong className="red">{userState.reportedGirls.length} </strong>
                    <div className="table-links inline" onClick={() => {setShowReportedGirls(!showReportedGirls)}}>See girls</div>
                </div>
                {showReportedGirls &&
                    <>
                    {userState.reportedGirls.map((u, i) => (
                        <div key={i}>
                            {u.isDeleted && <span >deleted: </span>}
                            <NavLink to={'/user/'+u.id}>
                                <span className="red"><b>{u.sex === 'female' ? 'F ' : 'M '}</b></span>{u.name}
                                {u.isBlocked && <span className="red"> (B)</span>}
                            </NavLink>
                        </div>
                        ))}
                    </>
                }
                </>
            }

            {userState.member.sex === 'female' && userState.verify_history &&
                <div>
                    <i className="fa fa-history green-text" aria-hidden="true"></i> Verify history:
                    <strong className="red">{userState.verify_history} </strong>
                    <div className="table-links inline" onClick={getVerificationHistory}> See history</div>
                    {showVerificationHistory && (
                        <VerificationHistory
                            list={verificationHistoryList}
                            userId={userState.member.ID}
                            onRemove={props.onReloadUser}
                        />
                    )}
                </div>

            }

            <div>IP duplicates:<div className="table-links inline" onClick={getIpDuplicates}> Load IP duplicates</div>
            {showIpDuplicates && ipDuplicates.map((ip, i) => {
                let sexFieldClass = (ip.sex === 'male') ? 'male' : 'female';
                let sexFieldContent = (ip.sex === 'male') ? 'M' : 'F';
                return (
                    <div className="pending-verification-duplicate" key={ip.member_id}>
                        {(ip.deleted_name) ? 'Deleted: ' : ''}
                        <span className={sexFieldClass}> <b>{sexFieldContent}</b> </span>
                        <NavLink to={`/user/${ip.member_id}`} className="table-links">
                            {(ip.name) ? ip.name : ip.deleted_name}
                        </NavLink>
                        {(parseInt(ip.blocked) > 0) ? <span className="red"> (B) </span> : ''}
                    </div>
                )
            })}
            </div>

            <div>User and Photo duplicates: <b className="red">{duplicates.length} </b>
                <div className="table-links inline" onClick={() => {setShowDuplicates(!showDuplicates)}}>See duplicates</div>
            </div>
            <PhotoDuplicates
                showDuplicates={showDuplicates}
                duplicatesList={duplicates}
                reportedUsers={reportedUsersId}
                currentUser={userState.member}
            />
            <div className="cl" />

            {userState.member.sex === 'male' && userState.member.tgPricepoint === 'trial' &&
                <div>Membership price: <span className="green-text">Free Trial</span></div>
            }
            {userState.member.sex === 'male' && userState.member.tgPricepoint === 'discount' &&
                <div>Membership price: <span className="green-text">Discount (19&euro;; 29&euro; rebills)</span></div>
            }
            {userState.member.sex === 'male' && userState.member.tgPricepoint === 'discount50' &&
                <div>Membership price: <span className="green-text">Discount (29&euro;)</span></div>
            }
            {userState.member.sex === 'male' && !userState.member.tgPricepoint &&
                <div>
                    Membership price: <span className="green-text">Default </span>
                    <span className="table-links" onClick={giveDiscount}><b>Give Discount</b></span>
                </div>
            }


            <div className="userBlacklist">
                {userState.member.canPay &&
                    <div className="table-links inline" onClick={addUserToBlackList}>Add user to blacklist</div>
                }
                {!userState.member.canPay &&
                    <>
                        <span className="red">Member can not pay! (blacklisted)</span>
                        <div className="table-links inline" onClick={removeUserFromBlackList}>Remove user from blacklist</div>
                    </>
                }

            </div>
        </>
    )
}

const PhotoDuplicates = ({showDuplicates, duplicatesList, reportedUsers, currentUser}) => {
    if (!duplicatesList) {
        return null;
    }

    let photoCount = 0;
    let duplicates = [];
    duplicatesList.forEach(item => {
        if (item.hasPhoto && photoCount < 14) {
            photoCount++;
            if(reportedUsers.indexOf(item.id) >= 0) {
                item.hasReports = true;
            }
            duplicates.push(item);
        }
    });
    return (
        <>
            {showDuplicates && duplicatesList.map((dup, i) => {
                let sexFieldClass = (dup.sex === 'male') ? 'male' : 'female';
                let sexFieldContent = (dup.sex === 'male') ? 'M' : 'F';
                return (
                    <div className="simpleDuplicate" key={dup.id}>
                        {(dup.isDeleted) ? 'Deleted: ' : ''}
                        <span className={sexFieldClass}> <b>{sexFieldContent}</b> </span>
                        <NavLink to={`/user/${dup.id}`} className="table-links">
                            {dup.name}
                        </NavLink>
                        {(dup.isBlocked) ? <span className="red"> <b>(B)</b></span> : ''}
                    </div>
                )
            })}
            {duplicates.length > 0 &&
                <div className="duplicatedPhotos" >
                    {duplicates.map(item => <DuplicateItem user={item} key={item.id} currentUser={currentUser} />)}
                </div>
            }
        </>
    )
}

const DuplicateItem = ({user, currentUser}) => {
    const [userObj, setUserObj] = useState(user);
    const [showExpanded, setShowExpanded] = useState(false);
    const inactiveClass = (userObj.activeAt * 1000) < (Date.now() - (604800000 * 2)) ? 'inactive' : '';

    let blockedClass = (userObj.isBlocked) ? 'inactive  blocked' : '';
    if (userObj.isDuplicated) blockedClass = 'duplicated';

    const blockUserHandler = () => {
        setUserObj({...userObj, isDuplicated: true});
    }

    return (
        <div className="duplicateWrapper">
            <div className={`fl duplicate ${inactiveClass} ${blockedClass}`} key={userObj.id} onClick={() => {setShowExpanded(!showExpanded)}} >
                {userObj.hasReports && <i className="fa fa-clipboard userWithReports" aria-hidden="true"></i>}
                <img src={aggPhoto(userObj.photoUrl, 'thumb')} className={`photo ${userObj.app}`} alt="dub foto" />
            </div>
            {showExpanded && <ExpandedDuplicate user={userObj} onClose={() => setShowExpanded(false)} onBlockUser={blockUserHandler} currentUser={currentUser}/>}
         </div>
    )
}

const ExpandedDuplicate = ({user, onClose, onBlockUser, currentUser}) => {
    const [copyVerification, setCppyVerification] = useState(false);

    const blockForDuplicate = () => {
        if (window.confirm('Are you sure \nDo you realy want to block a member for duplicated profile?')) {
            adminApi.post('users/duplicate_profile/', {userId: user.id, action: 'add'}).then(() => {
                onBlockUser();
                onClose();
            });

            if (copyVerification) {
                adminApi.post('verification/copy_verification/', {copyUserId: user.id, pasteUserId: currentUser.ID}).then((r) => {
                    window.location.reload();
                });
            }
        }
    }

    let logoImg = Logo;
    let headerClass = 'tg-duplicate-header';
    if (user.app === 'tf') {
        logoImg = TfLogo;
        headerClass = 'tf-duplicate-header';
    }
    if (user.app === 'tm') {
        logoImg = TravelLogo;
        headerClass = 'tm-duplicate-header';
    }
    return (
        <div className="expandedDuplicate">
            <div className={`${headerClass} duplicate-header`}>
                <img src={logoImg} height="16" alt=""/>
                <a href={aggProfileUrl(user.id)} >
                    {user.name} ({user.age})
                    {user.level !== 0 && <i className="fa fa-star premium" aria-hidden="true"></i>}
                    {user.level === 1 && <span> (Free premium)</span>}
                    {user.isVerified && <i className="fa fa-check-circle-o verified" aria-hidden="true"></i>}
                </a>
            </div>
            <div className="duplicateContent">
                <a href={aggProfileUrl(user.id)}>
                    <img width="130" src={aggPhoto(user.photoUrl, 'thumb')} alt="" />
                </a>
                <div className="info">
                    <div><b>Sex:</b> {user.sex}</div>
                    <div><b>Country: </b>{user.countryCode}</div>
                    <div><b title={user.activeAt}>Active at: </b> {timeAgo(user.activeAt)}</div>
                    <div><b>Reg. date: </b>{aggDateFromTimestamp(user.registeredAt)}</div>

                    {user.isBlocked &&
                        <>
                            <div className="blockUser">
                                <div><b className="red">This member is blocked! {user.block.blockedForever && <span>[Forever]</span>}</b></div>
                                <div>
                                    <b>by</b>:
                                    {user.block.adminBlock && <span>Admin - <b>{user.block.adminName}</b></span>}
                                    {user.block.systemBlock === '1' && <span>Auto - On registering</span>}
                                    {user.block.systemBlock === '2' && <span>Auto - On about-me update</span>}
                                    {user.block.systemBlock === '3' && <span>Report system</span>}
                                </div>
                            </div>

                            <div>Reason: {reportReasons[user.block.reason]}</div>
                            <div>Comment: {user.block.comment}</div>
                        </>
                    }

                    {user.isDuplicated &&
                        <div>
                            <b className="orange">This member is hidden!</b><br />
                            <b>Reason: Profile duplicate</b>
                        </div>
                    }

                    {!user.isDuplicated && !user.isBlocked &&
                    <div className="blockUser">
                        <span className="table-links" onClick={blockForDuplicate}><b>Block member (Duplicate profile)</b></span>
                    </div>
                    }
                    {user.isVerified && !user.isDuplicated &&
                        <div>
                            <input type="checkbox" id="copyVerification" checked={copyVerification} onChange={() => setCppyVerification(!copyVerification)} />
                            <label htmlFor="copyVerification">Copy verification</label>
                        </div>
                    }
                </div>
            </div>
            <div className="closeButton table-links" onClick={onClose}>
                <span><b>Close</b></span>
            </div>
        </div>
    )
}

export default TopInfo
